import { defineStore } from 'pinia';
import API from '@/shared/helpers/API.js';
import {useUserStore} from "@/console-new/stores";
import {getCompatibleDateOnly, getLocalDateFromServer} from "@/shared/helpers/dates";
import {useLanguageStore, useNotificationStore} from "@/shared/stores";
import {capitalizeFirstLetter} from "@/shared/helpers/strings";

export const useAnalyticsStore = defineStore('analytics', {
	state: () => ({
		//data needs to be separated for each section otherwise the charts animation will be buggy
		//also it can differ slightly from the data in the other sections
		orders: [],
		ordersToday: [],
		ordersTerminals: [],
		views: [],
		viewsToday: [],
		events: [],
		eventsToday: [],
		customers: [],
		customersToday: [],
		customersOrders: [],
		customersOrdersToday: [],
		feedbacksWithoutCustomer: [],
		productsOrders: [],
		productsOrdersToday: [],
		productsEvents: [],
		productsEventsToday: [],
		tablesOrders: [],
		tablesOrdersToday: [],
		tablesViews: [],
		tablesViewsToday: [],
		heatMap: [],
		weather: [],
		bestColors: ["#2CECBE", "#03c6f3", "#0752A3",
			"#FDDE00", "#e57e00", "#FC2291"],
		activeMenus: null,
		loadedViews: false,
		loadedOrders: false,
		loadedCustomers: false,
		loadedProducts: false,
		loadedTables: false,
	}),
	actions: {
		getStatsViews(startDate, endDate, today = false, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().analyticsViews(userStore.currBusinessId, startDate, endDate, response => {
					if (response && response.result === "OK") {
						(async () => {
							if(today) {
								this.viewsToday = this.normalizeViews(response.views);
								this.eventsToday = this.normalizeEvents(response.events);
							} else {
								this.views = this.normalizeViews(response.views);
								this.events = this.normalizeEvents(response.events);
							}
							callback && callback(response);
						})()
					}
				});
			}
		},
		getStatsHeatMap(startDate, endDate, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().analyticsHeatMap(userStore.currBusinessId, startDate, endDate, response => {
					if (response && response.result === "OK") {
						(async () => {
							this.heatMap = response.map;
							callback && callback(response);
						})()
					}
				});
			}
		},
		getStatsOrders(startDate, endDate, today = false, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().analyticsOrders(userStore.currBusinessId, startDate, endDate, userStore.manualCode, response => {
					if (response && response.result === "OK") {
						(async () => {
							if(today) {
								this.ordersToday = this.normalizeOrders(response.orders, response.orders_items, null, null, null, null, null, null, null, response.transactions, response.transactions_pos, response.payments, response.plugins, response.users);
							} else {
								this.orders = this.normalizeOrders(response.orders, response.orders_items, null, null, null, null, null, null, null, response.transactions, response.transactions_pos, response.payments, response.plugins, response.users);
								this.ordersTerminals = this.normalizeOrdersTerminals(response.orders_terminals);
							}
							callback && callback(response);
						})()
					} else if(response && response.result === "KO" && response.details === "auth_denied") {
						const notificationStore = useNotificationStore();
						notificationStore.showNotification("auth-denied-error");
					}
				});
			}
		},
		getWeather(startDate, endDate, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().getWeather(userStore.currBusinessId, startDate, endDate, response => {
					if (response && response.result === "OK") {
						(async () => {
							this.weather = this.normalizeWeather(response.weather);
							callback && callback(response);
						})()
					} else if(response && response.result === "KO" && response.details === "auth_denied") {
						const notificationStore = useNotificationStore();
						notificationStore.showNotification("auth-denied-error");
					}
				});
			}
		},
		getStatsCustomers(startDate, endDate, today = false, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().analyticsCustomers(userStore.currBusinessId, startDate, endDate, userStore.manualCode, response => {
					if (response && response.result === "OK") {
						(async () => {
							if(today) {
								this.customersOrdersToday = this.normalizeOrders(response.orders, null, response.orders_feedbacks);
								this.customersToday = this.normalizeCustomers(this.customersOrdersToday);
							} else {
								this.customersOrders = this.normalizeOrders(response.orders, null, response.orders_feedbacks);
								this.customers = this.normalizeCustomers(this.customersOrders);
							}
							callback && callback(response);
						})()
					}
				});
			}
		},
		getStatsProducts(startDate, endDate, today = false, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().analyticsProducts(userStore.currBusinessId, startDate, endDate, userStore.manualCode, response => {
					if (response && response.result === "OK") {
						(async () => {
							if(today) {
								this.productsOrdersToday = this.normalizeOrders(response.orders, response.orders_items, null, response.orders_items_products, null, null, response.menu_items, response.menu_item_list_products, response.menu_categories);
								this.productsEventsToday = this.normalizeEvents(response.events, response.menu_items);
							} else {
								this.productsOrders = this.normalizeOrders(response.orders, response.orders_items, null, response.orders_items_products, null, null, response.menu_items, response.menu_item_list_products, response.menu_categories);
								this.productsEvents = this.normalizeEvents(response.events, response.menu_items);
							}
							callback && callback(response);
						})()
					}
				});
			}
		},
		getStatsTables(startDate, endDate, today = false, callback = null) {
			let userStore = useUserStore();
			if(userStore.currBusinessId) {
				API.init().analyticsRooms(userStore.currBusinessId, startDate, endDate, userStore.manualCode, response => {
					if (response && response.result === "OK") {
						(async () => {
							if(today) {
								this.tablesOrdersToday = this.normalizeOrders(response.orders, null, null, null, response.rooms_tables, response.rooms);
								this.tablesViewsToday = this.normalizeViews(response.views, response.rooms_tables, response.rooms);
							} else {
								this.tablesOrders = this.normalizeOrders(response.orders, null, null, null, response.rooms_tables, response.rooms);
								this.tablesViews = this.normalizeViews(response.views, response.rooms_tables, response.rooms);
							}
							callback && callback(response);
						})()
					}
				});
			}
		},
		normalizeWeather(weather) {
			weather.forEach(w=>{
				w.date = getCompatibleDateOnly(w.date);
				w.date.setHours(12, 0, 0, 0);
				w.temperatureMin = Number(w.temperature_min);
				w.temperatureMax = Number(w.temperature_max);
				w.precipitation = Number(w.precipitation);
			});
			return weather;
		},
		normalizeOrders(orders, ordersItems = null, ordersFeedbacks = null, ordersItemsProducts = null, tables = null, rooms = null, menuItems = null, menuItemListProducts = null, menuCategories = null, transactions = null, transactionsPos = null, payments = null, plugins = null, users = null) {

			let ordersObj = {};
			let tablesObj = {};
			let roomsObj = {};
			let menuItemsObj = {};
			let menuItemListProductsObj = {};
			let menuCategoriesObj = {};
			let transactionsObj = {};
			let transactionsPosObj = {};
			let paymentsObj = {};
			let pluginsObj = {};
			let usersObj = {};

			if(tables) {
				tables.forEach(table=>{
					tablesObj[table.room_table_id] = table;
				});
			}
			if(rooms) {
				rooms.forEach(room=>{
					roomsObj[room.room_id] = room;
				});
			}
			if(menuItems) {
				menuItems.forEach(menuItem=>{
					menuItemsObj[menuItem.menu_item_id] = menuItem;
				});
			}
			if(menuItemListProducts) {
				menuItemListProducts.forEach(menuItemListProduct=>{
					menuItemListProductsObj[menuItemListProduct.menu_item_list_product_id] = menuItemListProduct;
				});
			}
			if(menuCategories) {
				menuCategories.forEach(menuCategory=>{
					menuCategory.menu_id = Number(menuCategory.menu_id)
					menuCategoriesObj[menuCategory.menu_category_id] = menuCategory;
				});
			}
			if(transactions) {
				transactions.forEach(transaction=>{
					transactionsObj[transaction.order_id] = transaction;
				});
			}
			if(transactionsPos) {
				transactionsPos.forEach(transactionPos=>{
					transactionsPosObj[transactionPos.pii] = transactionPos;
				});
			}
			if(plugins) {
				plugins.forEach(plugin=>{
					pluginsObj[plugin.plugin_id] = plugin;
				});
			}
			if(users) {
				users.forEach(user=>{
					user.station = user.station === "1";
					usersObj[user.user_id] = user;
				});
			}
			if(payments) {
				payments.forEach(payment=>{
					payment.amount = Number(payment.amount);
					payment.amountEur = payment.amount/100;
					payment.payment_user_id = payment.payment_user_id ? Number(payment.payment_user_id) : null;
					payment.payment_user = null;
					if(payment.payment_user_id) {
						payment.payment_user = usersObj[payment.payment_user_id] || null;
					}
					if(paymentsObj[payment.order_id]) {
						paymentsObj[payment.order_id].push(payment);
					} else {
						paymentsObj[payment.order_id] = [payment];
					}
				});
			}

			orders.forEach(order=>{
				order.date = getLocalDateFromServer(order.date);
				order.subtotal_delta = Number(order.subtotal_delta || 0);
				order.total = Number(order.total || 0);
				order.coverChargeTotal = (Number(order.cover_charge_num || 0)*Number(order.cover_charge_value || 0));
				order.totalEur = (order.total + order.coverChargeTotal + Number(order.delivery_costs_value || 0) + Number(order.takeaway_costs_value || 0) + order.subtotal_delta)/100;
				order.fee = Number(order.fee);
				order.feedback = null;
				order.staff_user_id = order.staff_user_id ? Number(order.staff_user_id) : null;
				order.staff_user = null;
				if(order.staff_user_id) {
					order.staff_user = usersObj[order.staff_user_id] || null;
				}
				order.payment_user_id = order.payment_user_id ? Number(order.payment_user_id) : null;
				order.payment_user = null;
				if(order.payment_user_id) {
					order.payment_user = usersObj[order.payment_user_id] || null;
				}
				ordersObj[order.order_id] = order;
				ordersObj[order.order_id].items = [];
				ordersObj[order.order_id].payments = paymentsObj[order.order_id] || [];
				ordersObj[order.order_id].table = null;
				ordersObj[order.order_id].room = null;
				ordersObj[order.order_id].transaction = null;
				ordersObj[order.order_id].plugin = null;

				if(plugins && order.plugin_id && pluginsObj.hasOwnProperty(order.plugin_id)) {
					ordersObj[order.order_id].plugin = pluginsObj[order.plugin_id];
				}

				if(tables && order.room_table_id && tablesObj.hasOwnProperty(order.room_table_id)) {
					ordersObj[order.order_id].table = tablesObj[order.room_table_id];
					if(rooms && ordersObj[order.order_id].table.room_id && roomsObj.hasOwnProperty(ordersObj[order.order_id].table.room_id)) {
						ordersObj[order.order_id].room = roomsObj[ordersObj[order.order_id].table.room_id];
					}
				}

				if(transactions && transactionsObj.hasOwnProperty(order.order_id)) {
					ordersObj[order.order_id].transaction = transactionsObj[order.order_id];
				} else if(transactionsPos && order.pii && transactionsPosObj.hasOwnProperty(order.pii)) {
					ordersObj[order.order_id].transaction = transactionsPosObj[order.pii];
				}
			});
			if(ordersItems) {
				let itemsObj = {};
				ordersItems.forEach(item=>{
					let subtotalScaling = 1.0;
					if(ordersObj[item.order_id].subtotal_delta !== 0 && ordersObj[item.order_id].total !== 0) {
						subtotalScaling = (ordersObj[item.order_id].subtotal_delta + ordersObj[item.order_id].total) / ordersObj[item.order_id].total;
					}

					item.products = [];
					item.quantity = Number(item.quantity);
					item.totalWithProductsInOrderEur = Number((Number(item.price)/100)*item.quantity)*subtotalScaling;
					itemsObj[item.order_item_id] = item;

					itemsObj[item.order_item_id].name = "";
					itemsObj[item.order_item_id].color = null;
					if(menuItems && item.item_id && menuItemsObj.hasOwnProperty(item.item_id)) {
						itemsObj[item.order_item_id].name = menuItemsObj[item.item_id].name;
						itemsObj[item.order_item_id].color = menuItemsObj[item.item_id].color && menuItemsObj[item.item_id].color.length === 6 ? "#"+menuItemsObj[item.item_id].color : null;
					}

					itemsObj[item.order_item_id].category_name = "";
					itemsObj[item.order_item_id].category_color = null;
					if(menuCategories && item.category_id && menuCategoriesObj.hasOwnProperty(item.category_id)) {
						itemsObj[item.order_item_id].menu_id = menuCategoriesObj[item.category_id].menu_id;
						itemsObj[item.order_item_id].category_name = menuCategoriesObj[item.category_id].name;
						itemsObj[item.order_item_id].category_color = menuCategoriesObj[item.category_id].color && menuCategoriesObj[item.category_id].color.length === 6 ? "#"+menuCategoriesObj[item.category_id].color : null;
					}

					ordersObj[item.order_id].items.push(item);
				});
				if(ordersItemsProducts) {
					ordersItemsProducts.forEach(product=>{
						if(itemsObj[product.order_item_id]) {
							let subtotalScaling = 1.0;
							if(ordersObj[product.order_id].subtotal_delta !== 0 && ordersObj[product.order_id].total !== 0) {
								subtotalScaling = (ordersObj[product.order_id].subtotal_delta + ordersObj[product.order_id].total) / ordersObj[product.order_id].total;
							}
							product.quantity = Number(product.quantity);
							product.totalQuantity = product.quantity * itemsObj[product.order_item_id].quantity;
							product.price = Number(product.price);
							itemsObj[product.order_item_id].totalWithProductsInOrderEur += (itemsObj[product.order_item_id].quantity * Number((product.price/100)*product.quantity))*subtotalScaling;
							product.name = "";
							product.totalEur = 0;
							if(menuItemListProducts && product.menu_item_list_product_id && menuItemListProductsObj.hasOwnProperty(product.menu_item_list_product_id)) {
								product.name = menuItemListProductsObj[product.menu_item_list_product_id].product_name;
								product.menu_item_linked_id = menuItemListProductsObj[product.menu_item_list_product_id].menu_item_linked_id
								product.totalEur = (itemsObj[product.order_item_id].quantity * (product.price/100)*product.quantity) * subtotalScaling;
							}
							itemsObj[product.order_item_id].products.push(product);
						}
					});
				}
			}
			if(ordersFeedbacks) {
				this.feedbacksWithoutCustomer = [];
				ordersFeedbacks.forEach(feedback=>{

					feedback.date = getLocalDateFromServer(feedback.date);
					feedback.feedback_id = Number(feedback.feedback_id);

					if(ordersObj.hasOwnProperty(feedback.order_id) && !!ordersObj[feedback.order_id].customer_token) {
						ordersObj[feedback.order_id].feedback = feedback;
					} else {
						this.feedbacksWithoutCustomer.push(feedback);
					}
				});
			}
			return Object.values(ordersObj);
		},
		normalizeOrdersTerminals(ordersTerminal) {
			let result = [];
			if(ordersTerminal) {
				ordersTerminal.forEach(orderTerminal=>{
					orderTerminal.total = Number(orderTerminal.total);
					orderTerminal.totalEur = Number(orderTerminal.total)/100;
					orderTerminal.date = getLocalDateFromServer(orderTerminal.date);
					result.push(orderTerminal);
				});
			}
			return result;
		},
		normalizeViews(views, tables = null, rooms = null) {

			let tablesObj = {};
			let roomsObj = {};

			if(tables) {
				tables.forEach(table=>{
					tablesObj[table.room_table_id] = table;
				});
			}
			if(rooms) {
				rooms.forEach(room=>{
					roomsObj[room.room_id] = room;
				});
			}

			views.forEach(view=>{
				view.date = getLocalDateFromServer(view.ds);
				view.visits_count = view.vc ? Number(view.vc) : 0;
				view.users_count = view.uc ? Number(view.uc) : 0;
				view.browser_data = view.bd ? JSON.parse(view.bd) : null;
				view.device_data = view.dd ? JSON.parse(view.dd) : null;
				view.os_data = view.od ? JSON.parse(view.od) : null;
				view.menu_data = view.md ? JSON.parse(view.md) : null;
				view.menu_user_data = view.mud ? JSON.parse(view.mud) : null;
				view.table_data = view.td ? JSON.parse(view.td) : null;
				view.table_user_data = view.tud ? JSON.parse(view.tud) : null;
				view.room_data = view.rd ? JSON.parse(view.rd) : null;
				view.room_user_data = view.rud ? JSON.parse(view.rud) : null;
				delete view.ds;
				delete view.vc;
				delete view.uc;
				delete view.bd;
				delete view.dd;
				delete view.od;
				delete view.md;
				delete view.mud;
				delete view.td;
				delete view.tud;
				delete view.rd;
				delete view.rud;
				if(tables) {
					if(view.table_data) {
						Object.entries(view.table_data).forEach(([room_table_id, value]) => {
							if(tablesObj.hasOwnProperty(room_table_id)) {
								view.table_data[room_table_id] = {};
								view.table_data[room_table_id].value = value;
								view.table_data[room_table_id].table = tablesObj[room_table_id];
							}
						});
					}
					if(view.table_user_data) {
						Object.entries(view.table_user_data).forEach(([room_table_id, value]) => {
							if(tablesObj.hasOwnProperty(room_table_id)) {
								view.table_user_data[room_table_id] = {};
								view.table_user_data[room_table_id].value = value;
								view.table_user_data[room_table_id].table = tablesObj[room_table_id];
							}
						});
					}
				}
				if(rooms) {
					if(view.room_data) {
						Object.entries(view.room_data).forEach(([room_id, value]) => {
							if(roomsObj.hasOwnProperty(room_id)) {
								view.room_data[room_id] = {};
								view.room_data[room_id].value = value;
								view.room_data[room_id].room = roomsObj[room_id];
							}
						});
					}
					if(view.room_user_data) {
						Object.entries(view.room_user_data).forEach(([room_id, value]) => {
							if(roomsObj.hasOwnProperty(room_id)) {
								view.room_user_data[room_id] = {};
								view.room_user_data[room_id].value = value;
								view.room_user_data[room_id].room = roomsObj[room_id];
							}
						});
					}
				}
			});
			views.sort((a, b) => {
				return a.date - b.date;
			});
			return views;
		},
		normalizeEvents(events, menuItems = null) {

			let menuItemsObj = {};

			if(menuItems) {
				menuItems.forEach(item=>{
					menuItemsObj[item.menu_item_id] = item;
				});
			}

			events.forEach(event=>{
				event.date = getLocalDateFromServer(event.ds);
				event.translations_count = event.tc ? Number(event.tc) : 0;
				event.translations_data = event.td ? JSON.parse(event.td) : null;
				event.scroll_bottom = event.sb ? Number(event.sb) : 0;
				event.zoom_image = event.zi ? Number(event.zi) : 0;
				event.menu_clicks = event.mc ? Number(event.mc) : 0;
				event.category_clicks = event.cc ? Number(event.cc) : 0;
				event.item_clicks = event.ic ? Number(event.ic) : 0;
				event.add_to_cart_data = event.ac ? JSON.parse(event.ac) : null;
				event.remove_from_cart_data = event.rc ? JSON.parse(event.rc) : null;
				event.time_avg = event.ta ? Number(event.ta) : 0;
				delete event.ds;
				delete event.tc;
				delete event.td;
				delete event.sb;
				delete event.zi;
				delete event.mc;
				delete event.cc;
				delete event.ic;
				delete event.ac;
				delete event.rc;
				delete event.ta;
				if(menuItems) {
					if(event.add_to_cart_data) {
						Object.entries(event.add_to_cart_data).forEach(([menu_item_id, value]) => {
							if(menuItemsObj.hasOwnProperty(menu_item_id)) {
								event.add_to_cart_data[menu_item_id] = {};
								event.add_to_cart_data[menu_item_id].value = value;
								event.add_to_cart_data[menu_item_id].product = menuItemsObj[menu_item_id];
							}
						});
					}
					if(event.remove_from_cart_data) {
						Object.entries(event.remove_from_cart_data).forEach(([menu_item_id, value]) => {
							if(menuItemsObj.hasOwnProperty(menu_item_id)) {
								event.remove_from_cart_data[menu_item_id] = {};
								event.remove_from_cart_data[menu_item_id].value = value;
								event.remove_from_cart_data[menu_item_id].product = menuItemsObj[menu_item_id];
							}
						});
					}
				}
			});
			events.sort((a, b) => {
				return a.date - b.date;
			});
			return events;
		},
		normalizeCustomers(orders) {

			let customersObj = {};
			orders.forEach(order=>{
				if(order.customer_token) {
					if(customersObj.hasOwnProperty(order.customer_token)) {
						customersObj[order.customer_token].ordersTotal += order.totalEur;
						customersObj[order.customer_token].orders.push(order);
					} else {
						customersObj[order.customer_token] = {
							orders: [order],
							feedbacks: [],
							ordersTotal: order.totalEur,
							customer_token: order.customer_token,
						}
					}
				}
			});
			let result = Object.values(customersObj);
			result.forEach(customer=>{
				if(customer.orders.length > 0) {
					customer.orders.sort((a, b) => {
						return b.date - a.date;
					});
					customer.date = customer.orders[customer.orders.length-1].date;
				} else {
					result.splice(result.indexOf(customer), 1);
				}
			});
			result.sort((a, b) => {
				return a.date - b.date;
			});
			return result;

		},
		normalizeTables(orders, views, events, rooms_tables, rooms) {

			let languageStore = useLanguageStore();

			let tablesObj = {};
			orders.forEach(order=>{

				if(order.room_table_id) {

					if(tablesObj.hasOwnProperty(order.room_table_id)) {
						tablesObj[order.room_table_id].totalEur += (Number(order.total)/100);
						tablesObj[order.room_table_id].orders.push(order);
					} else {
						tablesObj[order.room_table_id] = {
							orders: [order],
							views: [],
							events: [],
							room: {},
							name: languageStore.getString("unknown"),
							totalEur: 0,
						}
					}
				}

			});

			views.forEach(view=>{
				if(view.room_table_id) {
					if(tablesObj.hasOwnProperty(view.room_table_id)) {
						tablesObj[view.room_table_id].views.push(view);
					} else {
						tablesObj[view.room_table_id] = {
							orders: [],
							views: [view],
							events: [],
							room: {},
							name: languageStore.getString("unknown"),
							totalEur: 0,
						}
					}
				}
			});

			events.forEach(event=>{
				if(event.room_table_id) {
					if(
						event.name === "time"
					) {
						if(tablesObj.hasOwnProperty(event.value)) {
							tablesObj[event.value].events.push(event);
						}
					}
				}
			});

			let roomsObj = {};
			rooms.forEach(room=>{
				roomsObj[room.room_id] = room;
			});

			rooms_tables.forEach(room_table=>{
				if(tablesObj.hasOwnProperty(room_table.room_table_id)) {
					tablesObj[room_table.room_table_id].name = room_table.table_name;
					if(roomsObj.hasOwnProperty(room_table.room_id)) {
						tablesObj[room_table.room_table_id].room = roomsObj[room_table.room_id];
					}
				}
			});

			let result = Object.values(tablesObj);
			result.sort((a, b) => {
				return b.totalEur - a.totalEur;
			});
			return result;

		},
		xaxisTimes(periodDates) {

			let languageStore = useLanguageStore();

			let finalTimes = [];
			if (periodDates.timeframeIndex === 0) {
				for (let i = 0; i < 7; i++) {
					const date = new Date(periodDates.end);
					date.setDate(date.getDate() - 6 + i);
					let dateToRead = new Date(date);
					dateToRead.setDate(dateToRead.getDate() - 1);
					finalTimes.push({
						rawDate: date,
						label: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {weekday: 'short'})),
						label_full: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', weekday: 'short', month: '2-digit'}))
					});
				}
			} else if (periodDates.timeframeIndex === 1) {
				for (let i = 0; i < 28; i++) {
					const date = new Date(periodDates.end);
					date.setDate(date.getDate() - 27 + i);
					let dateToRead = new Date(date);
					dateToRead.setDate(dateToRead.getDate() - 1);
					finalTimes.push({
						rawDate: date,
						label: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: 'short', hour12: false})),
						label_full: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', weekday: 'short', month: '2-digit'}))
					});
				}
			} else if (periodDates.timeframeIndex === 2 || periodDates.timeframeIndex === 6 || periodDates.timeframeIndex === 7) {
				let startTime = periodDates.start;
				let pastDays = this.getDayDifference(startTime, periodDates.end);
				if (pastDays <= 2) {
					let values = this.getDatesEvery60MinutesTillNow(startTime, periodDates.end);
					values.forEach(time => {
						let label = capitalizeFirstLetter(time.toLocaleTimeString([languageStore.lang], {hour: '2-digit', minute: '2-digit', hour12: false}));
						finalTimes.push({
							rawDate: time,
							label: label,
							label_full: label,
						});
					});
				} else if (pastDays <= 31) {
					let values = this.getDatesEveryXDaysTillNow(startTime, 1, periodDates.end);
					values.forEach(time => {
						let dateToRead = new Date(time);
						dateToRead.setDate(dateToRead.getDate() - 1);
						finalTimes.push({
							rawDate: time,
							label: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: 'numeric', month: 'short'})),
							label_full: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', weekday: 'short', month: '2-digit'}))
						});
					});
				} else if (pastDays <= 98) {
					let values = this.getDatesEveryXDaysTillNow(startTime, 7, periodDates.end);
					values.forEach(time => {
						let timeToRead = new Date(time);
						timeToRead.setDate(timeToRead.getDate() - 1);
						let weekOfMonth = Math.floor((((timeToRead.getDate()-1) / 7) + 1));
						let dayOfMonthPreviousWeek = new Date(timeToRead);
						dayOfMonthPreviousWeek.setDate(dayOfMonthPreviousWeek.getDate() - 7);
						finalTimes.push({
							rawDate: time,
							label: weekOfMonth+languageStore.getString("week_letter")+"/"+capitalizeFirstLetter(timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {month: 'short'})),
							label_full: dayOfMonthPreviousWeek.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})+" - "+timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})
						});
					});
				} else {
					let values = this.getDatesEveryXDaysTillNow(startTime, 30, periodDates.end);
					values.forEach(time => {
						let timeToRead = new Date(time);
						timeToRead.setDate(timeToRead.getDate() - 1);
						let dayOfMonthPreviousMonth = new Date(timeToRead);
						dayOfMonthPreviousMonth.setDate(dayOfMonthPreviousMonth.getDate() - 30);
						finalTimes.push({
							rawDate: time,
							label: capitalizeFirstLetter(timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {month: 'short'})),
							label_full: dayOfMonthPreviousMonth.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})+" - "+timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})
						});
					});
				}
			} else if (periodDates.timeframeIndex === 3) {
				let startTime = new Date(periodDates.end);
				let daysAgo = 90;
				startTime.setDate(startTime.getDate() - daysAgo);
				let values = this.getDatesEveryXDaysTillNow(startTime, 7, periodDates.end);
				values.forEach(time => {
					let timeToRead = new Date(time);
					timeToRead.setDate(timeToRead.getDate() - 1);
					let weekOfMonth = Math.floor((((timeToRead.getDate()-1) / 7) + 1));
					let dayOfMonthPreviousWeek = new Date(timeToRead);
					dayOfMonthPreviousWeek.setDate(dayOfMonthPreviousWeek.getDate() - 7);
					finalTimes.push({
						rawDate: time,
						label: weekOfMonth+languageStore.getString("week_letter")+"/"+capitalizeFirstLetter(timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {month: 'short'})),
						label_full: dayOfMonthPreviousWeek.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})+" - "+timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})
					});
				});
			} else if (periodDates.timeframeIndex === 4) {
				let startTime = new Date(periodDates.end);
				let daysAgo = 180;
				startTime.setDate(startTime.getDate() - daysAgo);
				let values = this.getDatesEveryXDaysTillNow(startTime, 30, periodDates.end);
				values.forEach(time => {
					let timeToRead = new Date(time);
					timeToRead.setDate(timeToRead.getDate() - 1);
					let dayOfMonthPreviousMonth = new Date(timeToRead);
					dayOfMonthPreviousMonth.setDate(dayOfMonthPreviousMonth.getDate() - 30);
					finalTimes.push({
						rawDate: time,
						label: capitalizeFirstLetter(timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {month: 'short'})),
						label_full: dayOfMonthPreviousMonth.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})+" - "+timeToRead.toLocaleDateString([languageStore.lang || 'en-US'], {day: '2-digit', month: '2-digit'})
					});
				});
			} else if (periodDates.timeframeIndex === 5) {
				for (let i = 0; i < 12; i++) {
					const date = new Date(periodDates.end);
					date.setMonth(date.getMonth() - 11 + i);
					let dateToRead = new Date(date);
					dateToRead.setDate(dateToRead.getDate() - 1);
					finalTimes.push({
						rawDate: date,
						label: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {month: 'short'})),
						label_full: capitalizeFirstLetter(dateToRead.toLocaleDateString([languageStore.lang || 'en-US'], {month: 'long'}))
					});
				}
			}
			return finalTimes;
		},
		/*getDatesIndex(periodDates, xaxisTimes, order, isPrevious = false) {
			if(xaxisTimes.length === 0) {
				return -1;
			}
			let currentIndex = 0;
			let currGroupDate = xaxisTimes[currentIndex].rawDate;
			let comparingDate = order.date;
			if(isPrevious) {
				comparingDate = this.getPreviousComparingDate(periodDates, comparingDate);
			}
			while(comparingDate > currGroupDate && currentIndex < xaxisTimes.length - 1) {
				debugRemove++;
				currentIndex++;
				currGroupDate = xaxisTimes[currentIndex].rawDate;
			}
			if(currentIndex < 0 || currentIndex > xaxisTimes.length - 1) {
				currentIndex = -1;
			}
			console.log("DEBUG:", debugRemove);
			return currentIndex;
		},*/
		getDatesIndex(periodDates, xaxisTimes, order, isPrevious = false) {
			//Assumes xaxisTimes is sorted and the time interval between each date is the same
			if (xaxisTimes.length < 2) {
				return -1;
			}

			let comparingDate = isPrevious
				? this.getPreviousComparingDate(periodDates, order.date)
				: order.date;

			let interval = xaxisTimes[1].rawDate.getTime() - xaxisTimes[0].rawDate.getTime();
			if(interval === 0) {
				return -1;
			}
			let index = Math.ceil((comparingDate.getTime() - xaxisTimes[0].rawDate.getTime()) / interval);

			if (index < 0 || index >= xaxisTimes.length) {
				return -1;
			}

			return index;
		},
		getPreviousComparingDate(periodDates, comparingDate) {
			let returnDate = new Date(comparingDate);
			if(periodDates.timeframeIndex === 0) {
				returnDate.setDate(returnDate.getDate() + 7);
			} else if(periodDates.timeframeIndex === 1) {
				returnDate.setDate(returnDate.getDate() + 28);
			} else if(periodDates.timeframeIndex === 2) {
				returnDate.setMonth(returnDate.getMonth() + 1);
			} else if(periodDates.timeframeIndex === 3) {
				returnDate.setDate(returnDate.getDate() + 90);
			} else if(periodDates.timeframeIndex === 4) {
				returnDate.setDate(returnDate.getDate() + 180);
			} else if(periodDates.timeframeIndex === 5) {
				returnDate.setMonth(returnDate.getMonth() + 12);
			} else if(periodDates.timeframeIndex === 6) {
				returnDate.setFullYear(returnDate.getFullYear() + 1);
			} else if(periodDates.timeframeIndex === 7) {
				let dateDiff = periodDates.end.getTime() - periodDates.start.getTime();
				returnDate.setTime(returnDate.getTime() + dateDiff);
			}
			return returnDate;
		},
		numberOfDaysInTimeframePoint(periodDates) {
			let pastDays = this.getDayDifference(periodDates.start, periodDates.end);
			if(pastDays <= 2) {
				return 1;
			} else if(pastDays <= 31) {
				return 1;
			} else if(pastDays <= 98) {
				return 7;
			} else {
				return 30;
			}
		},
		firstDayOfCurrentMonth() {
			const userStore = useUserStore();
			let timeShift = userStore.currBusiness && userStore.currBusiness.dayShift ? userStore.currBusiness.dayShift : "03:00:00";
			let timeShiftArr = timeShift.split(":");
			const date = new Date();
			return new Date(date.getFullYear(), date.getMonth(), 1, Number(timeShiftArr[0]) || 3, Number(timeShiftArr[1]) || 0, Number(timeShiftArr[2]) || 0);
		},
		firstDayOfCurrentYear() {
			const userStore = useUserStore();
			let timeShift = userStore.currBusiness && userStore.currBusiness.dayShift ? userStore.currBusiness.dayShift : "03:00:00";
			let timeShiftArr = timeShift.split(":");
			const date = new Date();
			return new Date(date.getFullYear(), 0, 1, Number(timeShiftArr[0]) || 3, Number(timeShiftArr[1]) || 0, Number(timeShiftArr[2]) || 0);
		},
		firstDayOfLastMonth() {
			const date = this.firstDayOfCurrentMonth();
			date.setMonth(date.getMonth() - 1);
			return date;
		},
		firstDayOfLastYear() {
			const date = this.firstDayOfCurrentYear();
			date.setFullYear(date.getFullYear() - 1);
			return date;
		},
		getDatesEvery60MinutesTillNow(startDate, end = null) {
			let current = new Date();
			if(end) {
				current = new Date(end);
			}
			let dates = [];
			while (current > startDate) {
				dates.unshift(new Date(current));
				current.setMinutes(current.getMinutes() - 60);
			}
			return dates;
		},
		getDatesEveryXDaysTillNow(startDate, days, end = null) {
			let current = new Date();
			if(end) {
				current = new Date(end);
			} else {
				const userStore = useUserStore();
				let timeShift = userStore.currBusiness && userStore.currBusiness.dayShift ? userStore.currBusiness.dayShift : "03:00:00";
				let timeShiftArr = timeShift.split(":");
				current.setDate(current.getDate() + 1);
				current.setHours(Number(timeShiftArr[0]) || 3, Number(timeShiftArr[1]) || 0, Number(timeShiftArr[2]) || 0);
			}
			let dates = [];
			while (current > startDate) {
				dates.unshift(new Date(current));
				current.setDate(current.getDate() - days);
			}
			return dates;
		},
		getDayDifference(start, end) {
			let timeDiff = Math.abs(end.getTime() - start.getTime());
			return Math.ceil(timeDiff / (1000 * 3600 * 24));
		},
		getTargetStartDate(selectedTimeIndex) {
			let date = new Date();
			const userStore = useUserStore();
			let timeShift = userStore.currBusiness && userStore.currBusiness.dayShift ? userStore.currBusiness.dayShift : "03:00:00";
			let timeShiftArr = timeShift.split(":");
			date.setDate(date.getDate() + 1);
			date.setHours(Number(timeShiftArr[0]) || 3, Number(timeShiftArr[1]) || 0, Number(timeShiftArr[2]) || 0);
			switch (selectedTimeIndex) {
				case 0: date.setDate(date.getDate() - 7); break;
				case 1: date.setDate(date.getDate() - 28); break;
				case 2: date = this.firstDayOfCurrentMonth(); break;
				case 3: date.setDate(date.getDate() - 90); break;
				case 4: date.setDate(date.getDate() - 180); break;
				case 5: date.setDate(date.getDate() - 365); break;
				case 6: date = this.firstDayOfCurrentYear(); break;
			}
			date.setMinutes(date.getMinutes() + 1);
			return date;
		},
		getTargetEndDate() {
			let date = new Date();
			const userStore = useUserStore();
			let timeShift = userStore.currBusiness && userStore.currBusiness.dayShift ? userStore.currBusiness.dayShift : "03:00:00";
			let timeShiftArr = timeShift.split(":");
			date.setDate(date.getDate() + 1);
			date.setHours(Number(timeShiftArr[0]) || 3, Number(timeShiftArr[1]) || 0, Number(timeShiftArr[2]) || 0);
			return date;
		},
		getTargetPreviousStartDate(selectedTimeIndex) {
			let date = new Date(this.getTargetEndDate());
			switch (selectedTimeIndex) {
				case 0: date.setDate(date.getDate() - 7*2); break;
				case 1: date.setDate(date.getDate() - 28*2); break;
				case 2: date = this.firstDayOfLastMonth(); break;
				case 3: date.setDate(date.getDate() - 90*2); break;
				case 4: date.setDate(date.getDate() - 180*2); break;
				case 5: date.setDate(date.getDate() - 365*2); break;
				case 6: date = this.firstDayOfLastYear(); break;
			}
			date.setMinutes(date.getMinutes() + 1);
			return date;
		},
		getTargetPreviousEndDate(selectedTimeIndex) {
			let date = new Date(this.getTargetStartDate(selectedTimeIndex));
			let staticDaysIndexes = [0, 1, 3, 4, 5];
			if(staticDaysIndexes.includes(selectedTimeIndex)) {
				date.setMinutes(date.getMinutes() - 1);
			} else {
				date = this.getTargetEndDate();
				if(selectedTimeIndex === 2) {
					date.setMonth(date.getMonth() - 1);
				} else if(selectedTimeIndex === 6) {
					date.setFullYear(date.getFullYear() - 1);
				}
				const userStore = useUserStore();
				let timeShift = userStore.currBusiness && userStore.currBusiness.dayShift ? userStore.currBusiness.dayShift : "03:00:00";
				let timeShiftArr = timeShift.split(":");
				date.setHours(Number(timeShiftArr[0]) || 3, Number(timeShiftArr[1]) || 0, Number(timeShiftArr[2]) || 0);
			}
			return date;
		},
		getExecutedByFilterResult(filter, order) {
			let skip = false;

			if(filter.active) {
				let columnToCheck = filter.value.typeOfUserToFilterBy === 0 ? "staff_user_id" : "payment_user_id";
				let columnValue = filter.value.listOfUserToFilterBy[order[columnToCheck]];
				let userToFilterBy = filter.value.userToFilterBy ? columnValue : false;
				let onlineUser = false;
				if(filter.value.onlineClient) {
					onlineUser = order[columnToCheck] === null;
				}

				if(!userToFilterBy && !onlineUser) {
					skip = true;
				}
			}

			return skip;
		}
	},
});
